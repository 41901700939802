import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "cp-main-inner__wrapper" }
const _hoisted_2 = { class: "cp-uppercase" }
const _hoisted_3 = { class: "cp-page-sub-title" }
const _hoisted_4 = { class: "cp-form__wrapper" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_icon_font = _resolveComponent("icon-font")!
  const _component_title_return = _resolveComponent("title-return")!
  const _component_loading = _resolveComponent("loading")!
  const _component_form_block = _resolveComponent("form-block")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_title_return, {
      class: "cp-margin__tb-sx",
      onClick: _ctx.returnBefore
    }, {
      default: _withCtx(() => [
        _createElementVNode("span", _hoisted_2, _toDisplayString(_ctx.titlePage), 1),
        _createElementVNode("span", _hoisted_3, [
          _createVNode(_component_icon_font, {
            class: "cp-title-page__icon",
            size: "19",
            icon: "clients"
          }),
          _createElementVNode("span", null, _toDisplayString(_ctx.clientName), 1)
        ])
      ]),
      _: 1
    }, 8, ["onClick"]),
    _createElementVNode("div", _hoisted_4, [
      (_ctx.isLoading)
        ? (_openBlock(), _createBlock(_component_loading, { key: 0 }))
        : (_openBlock(), _createBlock(_component_form_block, {
            key: 1,
            "type-action": "edit",
            onReturnBefore: _ctx.returnBefore
          }, null, 8, ["onReturnBefore"]))
    ])
  ]))
}