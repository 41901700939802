
import { Component } from '@/lib/decorator';
import Form from '@/layouts/Form';
import TitleReturn from '../../../components/TitleReturn.vue';
import FormBlock from './_form.vue';
import IconFont from '@/components/icons/IconFont.vue';
import Loading from '@/components/Loading.vue';

import CustomersHelpersModule from '@/store/customers/helpers';
import CustomersHelperEntityModule from '@/store/customers/helpersEntity';
import CustomersModule from '@/store/customers';
import CustomerEntityModule from '@/store/customers/entity';

@Component({
  components: {
    TitleReturn,
    FormBlock,
    IconFont,
    Loading,
  },
  beforeMount() {
    this.customerId = this.$route.params.customerId as string;
    this.helperId = this.$route.params.helperId as string;
  },
})
export default class CustomerHelpersEdit extends Form {
  customerId = '';
  helperId = '';
  isLoading = true;

  get titlePage(): string {
    return CustomersHelpersModule.pageSettings.titleEdit;
  }

  get clientName(): string {
    return CustomersModule.pageSettings.titleEdit;
  }

  returnBefore(): void {
    this.$router.push({ name: 'customers_helpers', params: { customerId: this.customerId } });
  }

  async mounted(): Promise<void> {
    await CustomerEntityModule.initEdit(this.customerId);
    await CustomersHelperEntityModule.initEdit(this.helperId);

    this.isLoading = false;
  }
}
